export const baseurl = "https://node.kanabix.com"; //live

// export const baseurl = "https://node-ecommerce.mobiloitte.com"; // staging

// export const baseurl = "https://node-ecommerce.mobiloitte.com/api/v1/"; // staging

let admin = `${baseurl}/api/v1/admin`;
let user = `${baseurl}/api/v1/user`;
let product = `${baseurl}/api/v1/product`;
let strainType = `${baseurl}/api/v1/strainType`;
let effect = `${baseurl}/api/v1/effect`;
let effectAvoid = `${baseurl}/api/v1/effectAvoid`;
let helpWith = `${baseurl}/api/v1/helpWith`;
let flavor = `${baseurl}/api/v1/flavor`;
let terpene = `${baseurl}/api/v1/terpene`;
let strain = `${baseurl}/api/v1/strain`;
let deal = `${baseurl}/api/v1/deal`;
let order = `${baseurl}/api/v1/order`;
let static1 = `${baseurl}/api/v1/static`;

let brand = `${baseurl}/api/v1/brand`;
const Apiconfigs = {
  //Super Admin
  homeBanner: `${admin}/listBanner`,
  listCategory: `${admin}/listCategory`,
  viewCategory: `${admin}/viewCategory`,
  adminLogin: `${admin}/adminLogin`,
  editCategory: `${admin}/editCategory`,
  addCategory: `${admin}/addCategory`,
  deleteCategory: `${admin}/deleteCategory`,
  dashBoard: `${admin}/dashBoard`,
  listDispensaryCity: `${admin}/listDispensaryCity`,
  // listDispensaryCityWithCount: `${admin}/listDispensaryCityWithCount`,

  viewDispensaryCityWithAll: `${admin}/viewDispensaryCityWithAll`,
  editDispensaryCity: `${admin}/editDispensaryCity`,

  // Profile Section
  viewProfile: `${admin}/viewProfile`,
  editProfile: `${admin}/editProfile`,
  changePassword: `${admin}/changePassword`,
  forgotAdminPassword: `${admin}/forgotPassword`,
  resetAdminPassword: `${admin}/resetPassword`,
  resendOtp: `${admin}/resendOtp`,
  verifyOTP: `${admin}/verifyOTP`,
  subcategoryListWithCategory: `${admin}/subcategoryListWithCategory`,

  // SubCategory Details
  listSubCategory: `${admin}/listSubCategory`,
  viewSubCategory: `${admin}/viewSubCategory`,
  addSubcategory: `${admin}/addSubcategory`,
  editSubcategory: `${admin}/editSubcategory`,
  deleteSubCategory: `${admin}/deleteSubCategory`,
  // BANNER MANAGEMENT Deailst
  addBanner: `${admin}/addBanner`,
  viewBanner: `${admin}/viewBanner`,
  listBanner: `${admin}/listBanner`,
  deleteBanner: `${admin}/deleteBanner`,
  editBanner: `${admin}/editBanner`,

  // Review Management
  reviewListAdmin: `${admin}/reviewList`,
  viewReview: `${admin}/viewReview`,
  reviewApproveReject: `${admin}/reviewApproveReject`,

  // Brand Management
  listBrand: `${brand}/listBrand`,
  addBrand: `${brand}/addBrand`,
  deleteBrand: `${brand}/deleteBrand`,
  viewBrand: `${brand}/viewBrand`,
  editBrand: `${brand}/editBrand`,

  // User Management
  approvedUser: `${admin}/approvedUser`,
  listAllUser: `${admin}/listAllUser`,
  viewUser: `${admin}/viewUser`,
  deleteUser: `${admin}/deleteUser`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  productListOfParticularUser: `${admin}/productListOfParticularUser`,
  dealListOfParticularUser: `${admin}/dealListOfParticularUser`,
  storeListOfParticularUser: `${admin}/storeListOfParticularUser`,
  blockUnBlockProductOfUser: `${admin}/blockUnBlockProductOfUser`,
  activeBlockDealsOfUser: `${admin}/activeBlockDealsOfUser`,
  blockUnBlockStoreOfUser: `${admin}/blockUnBlockStoreOfUser`,

  // News Management listNews
  addNews: `${admin}/addNews`,
  listNews: `${admin}/listNews`,
  newsListByType: `${user}/newsListByType`,
  viewNews: `${admin}/viewNews`,
  deleteNews: `${admin}/deleteNews`,
  editNews: `${admin}/editNews`,
  addDispensaryCity: `${admin}/addDispensaryCity`,
  listSubscribedUser: `${admin}/listSubscribedUser`,

  //user
  signUp: `${user}/signUp`,
  verifyLink: `${user}/verifyLink`,
  usersLogin: `${user}/usersLogin`,
  myProfile: `${user}/myProfile`,
  forgotPassword: `${user}/forgotPassword`,
  forgotPasswordV2: `${user}/forgotPasswordV2`,
  resetPassword: `${user}/resetPassword`,
  socialLogin: `${user}/socialLogin`,
  uploadFile: `${user}/uploadFile`,
  uploadMultipleFile: `${user}/uploadMultipleFile`,

  //Product Details Product
  listProductV2: `${product}/listProductV2`,
  viewProductV2: `${product}/viewProductV2`,
  catSubAccordingProduct: `${product}/catSubAccordingProduct`,
  reviewProduct: `${product}/reviewProduct`,
  addProductV2: `${product}/addProductV2`,
  editProductV2: `${product}/editProductV2`,
  reviewList: `${product}/reviewList`,
  productOfOwn: `${product}/productOfOwn`,
  categoryAccordingProduct: `${product}/categoryAccordingProduct`,
  // Strain Management
  // ======>Strain Type Management <=====
  listStrainType: `${strainType}/listStrainType`,
  viewStrainType: `${strainType}/viewStrainType`,
  deleteStrainType: `${strainType}/deleteStrainType`,
  addStrainType: `${strainType}/addStrainType`,
  editStrainType: `${strainType}/editStrainType`,

  // ======>Effect Management <=====
  listEffect: `${effect}/listEffect`,
  addEffect: `${effect}/addEffect`,
  viewEffect: `${effect}/viewEffect`,
  deleteEffect: `${effect}/deleteEffect`,
  editEffect: `${effect}/editEffect`,

  // ======>Effect Avoid Management <=====
  listEffectAvoid: `${effectAvoid}/listEffectAvoid`,
  addEffectAvoid: `${effectAvoid}/addEffectAvoid`,
  viewEffectAvoid: `${effectAvoid}/viewEffectAvoid`,
  editEffectAvoid: `${effectAvoid}/editEffectAvoid`,
  deleteEffectAvoid: `${effectAvoid}/deleteEffectAvoid`,

  // ======>Help With Management <=====
  listHelpWith: `${helpWith}/listHelpWith`,
  addHelpWith: `${helpWith}/addHelpWith`,
  viewHelpWith: `${helpWith}/viewHelpWith`,
  deleteHelpWith: `${helpWith}/deleteHelpWith`,
  editHelpWith: `${helpWith}/editHelpWith`,

  // ======>Flavor Management <=====
  listFlavor: `${flavor}/listFlavor`,
  viewFlavor: `${flavor}/viewFlavor`,
  addFlavor: `${flavor}/addFlavor`,
  editFlavor: `${flavor}/editFlavor`,
  deleteFlavor: `${flavor}/deleteFlavor`,

  // ======>Terpene Management <=====
  listTerpene: `${terpene}/listTerpene`,
  viewTerpene: `${terpene}/viewTerpene`,
  addTerpene: `${terpene}/addTerpene`,
  deleteTerpene: `${terpene}/deleteTerpene`,
  editTerpene: `${terpene}/editTerpene`,

  // ======>Main Strain Management <=====
  listStrains: `${strain}/listStrains`,
  addStrains: `${strain}/addStrains`,
  viewStrain: `${strain}/viewStrain`,
  deleteStrain: `${strain}/deleteStrain`,
  editStrains: `${strain}/editStrains`,

  // Transaction Management
  transactionList: `${admin}/transactionList`,
  viewTransaction: `${admin}/viewTransaction`,
  dealAdd: `${deal}/dealAdd`,
  dealListOfParticular: `${deal}/dealListOfParticular`,
  viewDeal: `${deal}/viewDeal`,
  listDealByLocation: `${deal}/listDealByLocation`,
  editDeal: `${deal}/editDeal`,
  viewStore: `${user}/viewStore`,

  // Order Management
  allOrderListForAdmin: `${admin}/allOrderListForAdmin`,
  viewOrder: `${order}/viewOrder`,

  //Static Content
  listStaticContent: `${static1}/listStaticContent`,
  addStaticContent: `${static1}/addStaticContent`,
  editStaticContent: `${static1}/editStaticContent`,
  viewStaticContent: `${static1}/viewStaticContent`,

  //FAQ Management
  addFaq: `${static1}/addFaq`,
  viewFaq: `${static1}/viewFaq`,
  editFaq: `${static1}/editFaq`,
  deleteFaq: `${static1}/deleteFaq`,
  faqList: `${static1}/faqList`,
};

export default Apiconfigs;

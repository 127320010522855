import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    guard: true,
    path: "/users",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Userlist")),
  },
  {
    exact: true,
    guard: true,
    path: "/all-users",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/AllUSerList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/retailer",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/Retailer")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/doctor",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/DoctorList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/brand",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BrandManagement/BrandList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/brand-crud",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BrandManagement/AddViewEdit")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/lab-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/LabList")
    ),
  },
  // View user
  {
    exact: true,
    guard: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StackingMgmt/StackingTab")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sub-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/Sub_Category_Management/ListSubCategory"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/crud-subcategory",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/Sub_Category_Management/CrudSubCategory"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/userDetails",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Viewuser.js")),
  },
  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Profile/ChangePassword")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/bannerView",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Banner Management/AddViewEdit")
    ),
  },
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/Login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/logo",
    layout: LoginLayout,
    component: lazy(() => import("src/component/Logo")),
  },

  {
    exact: true,
    path: "/forget-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/Verify-otp",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/VerifyOtp.js")),
  },
  {
    exact: true,
    path: "/resend-otp",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/ResendOtp")),
  },
  {
    exact: true,
    path: "/instrauctions",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard:true,{n}
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    guard: true,
    path: "/stackingDetail",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/StakingDetails")),
  },

  {
    exact: true,
    guard: true,
    path: "/view-profile",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Profile/ViewProfile")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Profile/EditProfile")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/review-management",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReviewManagement/ReviewList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Static content Management/Index.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/review-action",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReviewManagement/ViewReviewAction")
    ),
  },

  {
    exact: true,
    path: "/Faq-management",
    // guard:true,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FAQ management/Index.js")
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    // guard:true,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FAQ management/AddFaq.js")
    ),
  },
  {
    exact: true,
    path: "/edit-faq",
    // guard:true,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FAQ management/EditFaq.js")
    ),
  },

  {
    exact: true,
    path: "/banner-management",
    // guard:true,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Banner Management/index")
    ),
  },

  // {
  //   exact: true,
  //   path: "/strains-management",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/StrainsManagement/index.js")
  //   ),
  // },
  {
    exact: true,
    path: "/strains-Type",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/StrainType/StrainType.js"
      )
    ),
  },
  {
    exact: true,
    path: "/crud-srain-type",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/StrainType/StrainAddViewEdit.js"
      )
    ),
  },
  {
    exact: true,
    path: "/strain-effects",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StrainsManagement/Effects/EffectsList")
    ),
  },
  {
    exact: true,
    path: "/effects-crud",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StrainsManagement/Effects/EffectsCrud")
    ),
  },
  {
    exact: true,
    path: "/effects-avoid-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/EffectAvoid/EffectAvoidList"
      )
    ),
  },
  {
    exact: true,
    path: "/effects-avoid",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/EffectAvoid/EffectAvoidCrud"
      )
    ),
  },
  {
    exact: true,
    path: "/helpwith-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/HelpWith/HelpWithList"
      )
    ),
  },
  {
    exact: true,
    path: "/helpwith-crud",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/HelpWith/HelpWithCrud"
      )
    ),
  },

  {
    exact: true,
    path: "/flavor-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StrainsManagement/Flavor/FlavorList")
    ),
  },
  {
    exact: true,
    path: "/flavor-crud",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StrainsManagement/Flavor/FlavorCrud")
    ),
  },

  {
    exact: true,
    path: "/terpene-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StrainsManagement/Terpene/TerpeneList")
    ),
  },
  {
    exact: true,
    path: "/terpene-crud",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StrainsManagement/Terpene/TerpeneCrud")
    ),
  },
  {
    exact: true,
    path: "/mainstrain-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/MainStrain/MainStrainTable"
      )
    ),
  },
  {
    exact: true,
    path: "/mainstrain-crud",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StrainsManagement/MainStrain/MainStrainCrud"
      )
    ),
  },

  {
    exact: true,
    path: "/news-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NewsManagement/index.js")
    ),
  },
  {
    exact: true,
    path: "/news",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NewsManagement/News_add_view_edit.js")
    ),
  },
  {
    exact: true,
    path: "/transaction-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TransactionManagement/TransactionList")
    ),
  },

  {
    exact: true,
    path: "/add-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/Static content Management/AddStaticContent.js"
      )
    ),
  },
  {
    exact: true,
    path: "/view-faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FAQ management/ViewFaq.js")
    ),
  },

  {
    exact: true,
    path: "/edit-faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FAQ management/EditFaq.js")
    ),
  },
  {
    exact: true,
    path: "/list-product",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddProduct/ListProduct.js")
    ),
  },
  {
    exact: true,
    path: "/view-product",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddProduct/ViewProduct.js")
    ),
  },
  {
    exact: true,
    path: "/add-product",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddProduct/AddProduct.js")
    ),
  },
  {
    exact: true,
    path: "/edit-product",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddProduct/EditProduct.js")
    ),
  },
  {
    exact: true,
    path: "/transaction-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TransactionManagement/TransactionView")
    ),
  },
  {
    exact: true,
    path: "/store-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/AddProduct/StoreManagement/StoreUser.js"
      )
    ),
  },
  {
    exact: true,
    path: "/retailer-product-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/RetailerProductView")
    ),
  },
  {
    exact: true,
    path: "/add-store",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddProduct/StoreManagement/AddStore.js")
    ),
  },
  {
    exact: true,
    path: "/retailer-deal-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/RetailerDealView")
    ),
  },
  {
    exact: true,
    path: "/retailer-store-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/RetailerStoreView")
    ),
  },
  {
    exact: true,
    path: "/view-dispensaries",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/DispensariesView")
    ),
  },
  {
    exact: true,
    path: "/view-clinic",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/DoctorClinicView")
    ),
  },
  {
    exact: true,
    path: "/view-cbdstore",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/CbdStoreView")
    ),
  },
  {
    exact: true,
    path: "/view-product-store",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/LabProductView")
    ),
  },
  {
    exact: true,
    path: "/view-store",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/AddProduct/StoreManagement/ViewStore.js"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-store",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddProduct/StoreManagement/Edittore.js")
    ),
  },
  {
    exact: true,
    path: "/deal-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DealManagement/YourDealsList.js")
    ),
  },
  {
    exact: true,
    path: "/view-deal",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DealManagement/DealView.js")
    ),
  },
  {
    exact: true,
    path: "/edit-deal",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DealManagement/EditDeal.js")
    ),
  },
  {
    exact: true,
    path: "/add-deal",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DealManagement/AddDealForm.js")
    ),
  },
  {
    exact: true,
    path: "/order-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/OrderManagement/OrderManagementList.js")
    ),
  },
  {
    exact: true,
    path: "/view-order",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/OrderManagement/ViewOrderManagement.js")
    ),
  },
  {
    exact: true,
    path: "/dispensary-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DispensariesManagement/DispensaryList")
    ),
  },
  {
    exact: true,
    path: "/dispensary",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DispensariesManagement/DispensaryCrud")
    ),
  },
  {
    exact: true,
    path: "/view-contents",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/Static content Management/ViewStaticContent.js"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-static-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/Static content Management/EditStaticContent.js"
      )
    ),
  },

  {
    exact: true,
    path: "/subcription-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subcription Management/Index.js")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
